import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AppService } from '@softbrik/data/services';

const SKIP_AUTH = [
  'stak-reply',
  'survey-submission',
  'trust-feedback',
  'feedback-signup',
  'signup',
  'verify/email',
];

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private app: AppService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.app.isFeedback = false;

    if (SKIP_AUTH.includes(route.routeConfig.path)) {
      this.app.isFeedback = true;
      return true;
    }

    if (this.app.currentUser) {
      return true;
    }

    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

    return false;
  }
}
