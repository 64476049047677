import {
  User,
  Brik,
  Customer,
  MessageDialog,
  PaginatedResponse,
  PaginationRequest,
  Update,
  Create,
} from '@softbrik/data/models';
import { Injectable, Output, EventEmitter, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeAll, shareReplay, tap } from 'rxjs/operators';
import {
  asyncScheduler,
  BehaviorSubject,
  Observable,
  of,
  scheduled,
} from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { createParams, isDevDomain } from './utils';
import {
  StorageKeyHandler,
  createKeyHandler,
  StorageType,
} from '@softbrik/shared/helpers';
import { countries, countryCodes, faqs } from './data';
import { DOCUMENT } from '@angular/common';
import {
  AnalyticsClientFacade,
  create as createAnalyticsClient,
} from './analytics';

const ALL_BRIKS = ['stak', 'trust', 'survey', 'admin', 'contact'];
const ADMIN_BRIKS = ['admin', 'contact'];

const cmpBriks = (a: Brik, b: Brik) => {
  let aIndex = ALL_BRIKS.indexOf(a.brik_id);
  let bIndex = ALL_BRIKS.indexOf(b.brik_id);

  aIndex = aIndex === -1 ? 999 : aIndex;
  bIndex = bIndex === -1 ? 999 : bIndex;

  if (aIndex === bIndex) return 0;

  return aIndex > bIndex ? 1 : -1;
};

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public store: StorageKeyHandler = createKeyHandler('app', StorageType.LOCAL);
  public session: StorageKeyHandler = createKeyHandler(
    'app',
    StorageType.SESSION
  );
  @Output() messageDialogResult = new EventEmitter<boolean>();

  public API_LINK = '';

  public version: string;
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  public is_loading: boolean = false;

  /** @deprecated not reliable await new approach */
  public currentBrik: string = 'dashboard';
  /** @deprecated not reliable await new approach */
  public isFeedback = false;
  /** @deprecated not reliable await new approach */
  public showPreview = false;
  public showHelp: boolean = false;
  public isShowMessage: boolean = false;
  public dialogMessage: MessageDialog;
  public showNotification: boolean = false;
  public notificationTitle: string;
  public notificationText: string;
  public notificationType: string;
  public notificationTimeout = 5000;
  public feedbackDone: boolean = false;

  public analytics: AnalyticsClientFacade;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if (!this.API_LINK) {
      this.API_LINK = localStorage.getItem('APP_API_LINK');
    }
    this.initUser();
    this.initAnalytics();
  }

  /**
   * Get customer alias. For local and ngrok it falls back to 'softdrinks'.
   */
  get customerAlias() {
    return isDevDomain(this.document.location.hostname)
      ? 'softdrinks'
      : this.document.location.hostname.split('.')[0];
  }

  get business() {
    return this.decodeToken()?.business || this.getQueryParam('business');
  }

  get domain() {
    return window.location.hostname.split('.').slice(-2).join('.');
  }

  /**
   * Get the domain URL under any environment: local, ngrok'ed, or in
   * production.
   */
  get domainUrl() {
    const domain = this.document.location.hostname;
    const port = this.document.location.port;
    const port_ = port ? `:${port}` : '';
    return isDevDomain(domain)
      ? `${this.document.location.protocol}//${domain}${port_}`
      : `https://${this.customerAlias}.${this.domain}`;
  }

  /**
   * Creates a public URL with the proper language, customer and business
   * settings.
   */
  createPublicURL(path: string, params?: Record<string, string>) {
    const params_ = this.createPublicURLParams(params?.language || 'en');
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        // Language is already added
        if (key === 'language') return;

        params_.append(key, value);
      });
    }
    return `${this.domainUrl}/${path}?${params_}`;
  }

  /**
   * Create the query params most likely need for any public URL.
   *
   * @see createPublicURL()
   */
  createPublicURLParams(language: string) {
    return new URLSearchParams({
      customer: this.customerId.toString(),
      business: this.business || 'support',
      language: language || 'en',
    });
  }

  /**
   * Customer ID from token
   */
  get customerId() {
    return this.decodeToken()?.customer_id;
  }

  /**
   * Get customer ID from token or query string. If you don't know why you
   * should use this method, then you should use `customerId`.
   */
  get customerIdAny() {
    return (
      this.customerId ||
      this.getQueryParam('customer') ||
      this.getQueryParam('customerId')
    );
  }

  private getQueryParam(key: string) {
    return this.route.snapshot.queryParams?.[key];
  }

  private initUser() {
    this.userSubject = new BehaviorSubject<User>(this.restoreUser());
    this.user = this.userSubject.asObservable();
  }

  private initAnalytics() {
    createAnalyticsClient({
      customerAlias: this.customerAlias,
    }).then((analytics) => {
      this.analytics = analytics;
    });
  }

  restoreUser(): User | null {
    const userJson = localStorage.getItem('user');
    return userJson ? JSON.parse(userJson) : null;
  }

  /**
   * E.g. trust.users.add
   */
  userCanPath(path: string) {
    const [brik, feature, action] = path.split('.');

    return this.userCan(brik, feature, action);
  }

  userCan(brik: string, feature: string, action: string): boolean {
    // TODO check brik, feature, and action with middle-layer
    // TODO check action with with ACL
    // HACK: For now we want to disable add user for Yalo
    const customerAlias = this.customerAlias;
    if (
      brik === 'trust' &&
      feature === 'users' &&
      action === 'add' &&
      customerAlias === 'yallo'
    ) {
      return false;
    }

    return true;
  }

  navigate(route: any) {
    // TODO call from menuService
    // this.clearSidebar();
    this.router.navigate([route]);
  }

  childNavigate(
    route: string,
    outlet: string,
    child: string,
    params: any[] = []
  ) {
    this.router.navigate([
      `/${route}`,
      { outlets: { [outlet]: [child, ...params] } },
    ]);
  }

  public get currentUser(): User {
    return this.userSubject.value;
  }

  public decodeToken() {
    const user = this.restoreUser();
    if (!user) return;
    return this.decode(user.token);
  }

  public decode<T = any>(token: string | null) {
    const jwt = new JwtHelperService();
    return jwt.decodeToken<T>(token);
  }

  login(credentials: { email: string; password: string }) {
    return this.http
      .post<User>(`${this.API_LINK}/auth/user/login`, credentials)
      .pipe(
        map((user) => {
          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);

          this.updateBusiness();

          return user;
        })
      );
  }

  /**
   * Update business and reload if needed
   */
  private updateBusiness() {
    const existingBusiness = window.localStorage.getItem('business');
    const business = this.business || existingBusiness;
    window.localStorage.setItem('business', business);
    if (existingBusiness && existingBusiness !== business) {
      window.localStorage.removeItem('translations');
      window.location.reload();
    }
  }

  logout() {
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.is_loading = false;
    this.router.navigate(['/login']);
  }

  isAuthenticated() {
    return this.router.url != '/login' && this.currentUser;
  }

  changePassword(credentials: {
    email: string;
    password: string;
    new_password: string;
    confirm_password: string;
    customer_alias: string;
  }) {
    return this.http.post(
      `${this.API_LINK}/auth/user/change-password`,
      credentials
    );
  }

  getUserCommonBriks() {
    return this.getUserBriks().pipe(
      map((briks) => briks.filter((brik) => !ADMIN_BRIKS.includes(brik.id)))
    );
  }

  getUserAdminBriks() {
    return this.getUserBriks().pipe(
      map((briks) => briks.filter((brik) => ADMIN_BRIKS.includes(brik.id)))
    );
  }

  getUserBriks() {
    return scheduled<Observable<Brik[]>>(
      [
        of(this.session.getItem('briks') || []),
        this.http.get<Brik[]>(`${this.API_LINK}/auth/user/briks`),
      ],
      asyncScheduler
    )
      .pipe(
        mergeAll(),
        tap((briks) => this.session.setItem('briks', briks))
      )
      .pipe(map((briks) => briks.sort(cmpBriks)))
      .pipe(shareReplay());
  }

  getUsers({ link, ...params }: PaginationRequest & { link: string }) {
    const query = createParams(params);
    return this.http.get<PaginatedResponse<User>>(
      `${link}/auth/customer/users?${query}`
    );
  }

  getAccount(link: string) {
    return this.http.get<Customer>(`${link}/sub/subscription`);
  }

  getInvoices(link: string, query?: string) {
    return this.http.get<PaginatedResponse<any>>(
      `${link}/sub/customer/invoice?${query}`
    );
  }

  getPaymentMethod(link: string) {
    return this.http.get(`${link}/sub/payment-method`);
  }

  createPaymentMethod(link: string, data: unknown) {
    return this.http.post(`${link}/sub/payment-method`, data);
  }

  deletePaymentMethod(link: string, id: string | number) {
    return this.http.delete(`${link}/sub/payment-method/${id}`);
  }

  updateCustomer(customer: User) {
    return this.http.put<User>(
      `${this.API_LINK}/auth/customer/${customer.id}`,
      customer
    );
  }

  createUser(user: Create<User>) {
    return this.http.post(`${this.API_LINK}/auth/user/register`, user);
  }

  updateUser(user: Update<User>) {
    return this.http.put<User>(`${this.API_LINK}/auth/user/${user.id}`, user);
  }

  toggleLogin(user: User) {
    return this.http.put(`${this.API_LINK}/auth/toggle-login`, user);
  }

  sendPasswordResetEmail(email: string, customer_alias: string) {
    return this.http.post(`${this.API_LINK}/auth/user/reset`, {
      email,
      customer_alias,
    });
  }

  toggleShowMessage(
    message: Partial<MessageDialog> & { content: string | Promise<string> }
  ) {
    this.dialogMessage = {
      type: 'confirm',
      showOk: true,
      showCancel: false,
      ...message,
    };

    if (typeof message.title === 'string') {
      this.dialogMessage.title = Promise.resolve(message.title);
    }
    if (typeof message.content === 'string') {
      this.dialogMessage.content = Promise.resolve(message.content);
    }
    if (typeof message.okLabel === 'string') {
      this.dialogMessage.okLabel = Promise.resolve(message.okLabel);
    }
    if (typeof message.cancelLabel === 'string') {
      this.dialogMessage.cancelLabel = Promise.resolve(message.cancelLabel);
    }

    this.isShowMessage = !this.isShowMessage;
  }

  async notify(
    title: string | Promise<string>,
    text: string | Promise<string> = '',
    type: 'success' | 'error' = 'success',
    timeout: number = 5000
  ) {
    this.notificationTitle = typeof title === 'string' ? title : await title;
    this.notificationText = typeof text === 'string' ? text : await text;
    this.notificationType = type;
    this.notificationTimeout = timeout;

    this.showNotification = true;

    setTimeout(() => {
      this.showNotification = false;
      this.notificationTitle = '';
      this.notificationText = '';
      this.notificationType = '';
    }, timeout + 1000);
  }

  async notifyError(
    message: string | Promise<string>,
    error: Error,
    timeout?: number
  ) {
    this.notify(
      'Error',
      `${await message}: ${typeof error === 'string' ? error : error.message}`,
      'error',
      timeout
    );
    console.error(error);
  }

  public countries: Array<string> = countries;
  public countryCodes: Array<any> = countryCodes;
  public helpItems = faqs;

  generatePageTemplate(value: string, title?: string) {
    return `
    <html>
        <head>
          <title>${title || 'Print'}</title>
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/paper-css/0.3.0/paper.css">
          <style>
            body {
              display: flex;
              flex: 1;
              justify-content: center;
              align-items: center;
            }
            body.label.landscape .sheet {
              width: 66.675mm;
              height: 66.675mm;
              padding: 8px;
              flex-direction: column;
              align-content: center;
              align-items: center;
              justify-content: center;
            }
            @page {
              size: label landscape
            }
            article {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            img {
              height: 80%;
              width: 80%;
            }
            h3 {
              font-family: 'Montserrat', sans-serif;
            }
          </style>
        </head>
        <body class="label landscape">
          <section class="sheet">
            <article>
              <img src="${value}" />
              <br/>
              <h3>Scan to give feedback</h3>
            </article>
          </section>
        </body>
      </html>`;
  }

  togglePreview() {
    this.showPreview = !this.showPreview;
  }
}
