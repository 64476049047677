import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppService } from '@softbrik/data/services';

const LOGOUT_ON = [401, 0];

const EXCEPT_FOR_PATHS = [
  '/change-password',
  '/forgot-password',
  '/login',
  '/survey-submission',
  '/stak-reply',
  '/trust-feedback',
  '/feedback-signup',
  '/verify/email',
];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private app: AppService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const path = window.location.pathname;
        if (
          LOGOUT_ON.includes(err.status) &&
          !EXCEPT_FOR_PATHS.includes(path) &&
          err.error.type !== 'abort'
        ) {
          this.app.logout();
        }
        const error = err.error?.message || err.error || err;
        return throwError(error);
      })
    );
  }
}
