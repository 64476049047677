import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { IssueCommentComponent } from './issue-comment/issue-comment.component';
import { ActionBarComponent } from './action-bar/action-bar.component';
import { ActionPortalComponent } from './action-portal/action-portal.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SidebarPortalComponent } from './sidebar-portal/sidebar-portal.component';
import {
  BytesPipe,
  LocaleDatePipe,
  LocaleTimePipe,
  TimeAgoPipe,
  TimeRelativePipe,
} from './pipes';
import { TranslateModule } from '@softbrik/translate';
import { BackButtonComponent } from './back-button/back-button.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { QrCardComponent } from './qr-card/qr-card.component';
import { QrModalComponent } from './qr-modal/qr-modal.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { DateViewComponent } from './date-view/date-view.component';

const components = [
  // Components
  ActionBarComponent,
  ActionPortalComponent,
  BackButtonComponent,
  DatePickerComponent,
  DateViewComponent,
  IssueCommentComponent,
  SidebarComponent,
  SidebarPortalComponent,
  PageLoaderComponent,
  QrCardComponent,
  QrModalComponent,
  // Pipes
  BytesPipe,
  LocaleDatePipe,
  LocaleTimePipe,
  TimeAgoPipe,
  TimeRelativePipe,
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule],
  declarations: components,
  exports: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiCoreModule {}
