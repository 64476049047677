export const environment = {
  production: true,
  branch: 'master',
  bugsnagApiKey: '121ea2a1a1400f165e54cb2ce7365249',
  api: 'https://dev-api.softbrik.com',
  allo: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  stak: 'https://irgwmvex84.execute-api.eu-west-1.amazonaws.com/prod',
  trak: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  file: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  contacts: 'https://t535yfdnb6.execute-api.eu-west-1.amazonaws.com/prod',
  trust: 'https://vry0kitb9g.execute-api.eu-west-1.amazonaws.com/prod',
  health: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  survey: 'https://19qtgjv3n9.execute-api.eu-west-1.amazonaws.com/prod',
};
